/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import { titlesValidation } from "../recipe-filters/recipe-filters-model";
import { string, bool } from "yup";

// Validation Schema
export const hyphenatedNameValidation = string()
  .required("Required")
  .matches(/^[a-zA-Z-]+$/, {
    message: "Only letters and hyphens are allowed",
    excludeEmptyString: true,
  })
  .max(40, "Maximum 40 characters are allowed");

// Helper Functions
const formatDate = (dateValue) => {
  if (!dateValue) return "";
  const date = normalizeDate(dateValue);
  if (!date) return "";
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const normalizeDate = (timestamp) => {
  if (!timestamp) return null;
  return timestamp.seconds ? new Date(timestamp.seconds * 1000) : new Date(timestamp);
};

// Styles
const styles = {
  listLayoutContainer: (width) => ({
    maxWidth: width,
    overflowX: "auto",
    WebkitOverflowScrolling: "touch",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  }),
  capitalizedText: {
    textTransform: "capitalize",
  },
};

// Control Modes Model
const controlModesModel = {
  name: "Control Modes",
  model: "controlModes",
  route: "controlModes",
  collectionName: "controlModes",
  defaultObject: {
    id: "",
    description: null,
    helpTextMarkDown: "",
    name: null,
    firmwareName: null,
    firmwareId: null,
    isPowerRequired: "NOT-REQUIRED",
    isTimeRequired: "NOT-REQUIRED",
    isCentralProbeTempratureRequired: "NOT-REQUIRED",
    isExternalProbeTempratureRequired: "NOT-REQUIRED",
    isExternalProbeEnabled: false,
    isControlViaExternalProbe: false,
    showInCustomPresets: true,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  },
  fields: [
    {
      name: "name",
      dataType: "String",
      inputFieldType: "text-modified",
      label: "Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: hyphenatedNameValidation,
      listLayoutComponent: (props) => (
        <div style={styles.listLayoutContainer(props.listLayoutWidth)}>
          <span style={styles.capitalizedText}>{props.row?.name}</span>
        </div>
      ),
    },
    {
      name: "firmwareName",
      dataType: "String",
      inputFieldType: "firmwareName-text-modified",
      label: "Firmware Name",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      validation: hyphenatedNameValidation,
      listLayoutComponent: (props) => (
        <div style={styles.listLayoutContainer(props.listLayoutWidth)}>
          <span>{props.row?.firmwareName}</span>
        </div>
      ),
    },
    {
      name: "firmwareId",
      dataType: "String",
      inputFieldType: "noChar-text",
      label: "FirmwareID",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      required: true,
      validation: titlesValidation,
      listLayoutComponent: (props) => (
        <div style={styles.listLayoutContainer(props.listLayoutWidth)}>
          <span>{props.row?.firmwareId}</span>
        </div>
      ),
    },
    {
      name: "description",
      dataType: "String",
      inputFieldType: "noChar-noValid-text",
      label: "Description",
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 300,
      listLayoutComponent: (props) => (
        <div style={styles.listLayoutContainer(props.listLayoutWidth)}>
          <span>{props.row?.description}</span>
        </div>
      ),
    },
    {
      name: "helpTextMarkDown",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Help Text",
      showInEditLayout: true,
      editLayoutWidth: 12,
    },
    {
      name: "isPowerRequired",
      dataType: "String",
      inputFieldType: "select",
      options: ["OPTIONAL", "REQUIRED", "NOT-REQUIRED"],
      label: "Power",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 100,
      validation: string().required("Power is required"),
    },
    {
      name: "isTimeRequired",
      dataType: "String",
      inputFieldType: "select",
      options: ["OPTIONAL", "REQUIRED", "NOT-REQUIRED"],
      label: "Time",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 100,
      validation: string().required("Time is required"),
    },
    {
      name: "isCentralProbeTempratureRequired",
      dataType: "String",
      inputFieldType: "select",
      options: ["OPTIONAL", "REQUIRED", "NOT-REQUIRED"],
      label: "Central Probe Temprature",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 100,
      validation: string().required("required"),
    },
    {
      name: "isExternalProbeTempratureRequired",
      dataType: "String",
      inputFieldType: "select",
      options: ["OPTIONAL", "REQUIRED", "NOT-REQUIRED"],
      label: "External Probe Temprature",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 100,
      validation: string().required("required"),
    },
    {
      name: "isExternalProbeEnabled",
      dataType: "Boolean",
      inputFieldType: "notRequired-checkbox",
      label: "External Probe",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 200,
      validation: bool().required("required"),
    },
    {
      name: "isControlViaExternalProbe",
      dataType: "Boolean",
      inputFieldType: "notRequired-checkbox",
      label: "Control Via External Probe",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 200,
      validation: bool().required("required"),
    },
    {
      name: "showInCustomPresets",
      dataType: "Boolean",
      inputFieldType: "checkbox",
      label: "Show In Custom Presets",
      showInEditLayout: true,
      editLayoutWidth: 6,
      validation: bool().required("required"),
    },
    {
      name: "createdAt",
      dataType: "Date",
      inputFieldType: "string",
      label: "Created At",
      showInListLayout: true,
      listLayoutWidth: 125,
      listLayoutComponent: (props) => (
        <div style={styles.listLayoutContainer(props.listLayoutWidth)}>
          <span>{formatDate(props.row?.createdAt)}</span>
        </div>
      ),
    },
    {
      name: "updatedAt",
      dataType: "Date",
      inputFieldType: "string",
      label: "Last Updated",
      showInListLayout: true,
      listLayoutWidth: 125,
      listLayoutComponent: (props) => (
        <div style={styles.listLayoutContainer(props.listLayoutWidth)}>
          <span>{formatDate(props.row?.updatedAt)}</span>
        </div>
      ),
    },
    {
      name: "semiScreenId",
      dataType: "String",
      inputFieldType: "text",
      label: "Semi Screen Id",
      showInListLayout: true,
      listLayoutWidth: 200,
    },
  ],
  layout: [
    [{ name: "name", width: 6 }],
    [
      { name: "firmwareId", width: 3 },
      { name: "firmwareName", width: 3 },
    ],
    [{ name: "description", width: 12 }],
    [
      { name: "isPowerRequired", width: 6 },
      { name: "isTimeRequired", width: 6 },
    ],
    [
      { name: "isCentralProbeTempratureRequired", width: 6 },
      { name: "isExternalProbeTempratureRequired", width: 6 },
    ],
    [
      { name: "isExternalProbeEnabled", width: 6 },
      { name: "isControlViaExternalProbe", width: 6 },
    ],
    [{ name: "showInCustomPresets", width: 6 }],
  ],
  permittedActions: [
    {
      name: "create",
      label: "Create",
      type: "create",
      icon: "add",
      color: "primary",
    },
    {
      name: "edit",
      label: "Edit",
      type: "edit",
      icon: "edit",
      color: "primary",
    },
    {
      name: "delete",
      label: "Delete",
      type: "delete",
      icon: "delete",
      color: "primary",
    },
  ],
};

export default controlModesModel;
