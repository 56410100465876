import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { EmptyImageBox } from "../../rct-isolated-section/components/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { v4 as uuidv4 } from "uuid";
import fileUpload from "../../rct-isolated-section/assets/file-upload.png";
import { storage } from "../../../config/firebase-config";
import { imageUploaderStyles } from "../../rct-isolated-section/components/styles";

export default function ImageUploader({
  name,
  value,
  register,
  setFormValue,
  storageLocation,
  error,
}) {
  const [image, setImage] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isImageUpdated, setIsImageUpdated] = useState(false);
  const [isImageUploadComplete, setIsImageUploadComplete] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (typeof value === "string" && value !== "") {
      const image = {
        file: null,
        fileName: value,
        status: "UPLOADED",
        storageRef: null,
        downloadURL: value,
        description: "",
      };
      setImage(image);
    }
  }, [value]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 150 * 1024) {
      setUploadError("Image size should not exceed 150kb");
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      return;
    }

    setUploadError("");
    const uuid = uuidv4();
    const newImage = {
      file,
      fileName: file.name,
      status: "CREATED",
      storageRef: storage
        .ref()
        .child(
          `${storageLocation}/original/${
            storageLocation.split("/")[1]
          }--${uuid}--${file.name}`
        ),
      downloadURL: URL.createObjectURL(file),
      description: "",
    };

    setImage(newImage);
    setIsImageUpdated(true);
    handleUpload(newImage);
  };

  const handleDelete = () => {
    setImage(null);
    setIsImageUpdated(true);
    setIsImageUploadComplete(true);
    setUploadError("");
    setFormValue(name, "");
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleUpload = (newImage) => {
    if (newImage) {
      newImage.storageRef.put(newImage.file).on(
        "state_changed",
        (snapshot) => {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          setUploadError("Upload failed. Please try again.");
        },
        () => {
          newImage.storageRef.getDownloadURL().then((url) => {
            newImage.downloadURL = url;
            newImage.status = "UPLOADED";
            setUploadProgress(0);
            setImage(newImage);
            setIsImageUpdated(true);
            setIsImageUploadComplete(true);
            setFormValue(name, newImage.downloadURL);
          });
        }
      );
    }
  };

  const renderUploadBox = () => {
    if (uploadError || error) {
      return (
        <EmptyImageBox
          sx={imageUploaderStyles.errorBox}
          onClick={() => {
            fileInputRef.current.click();
          }}
        >
          <Typography
            my={1}
            variant="subtext2"
            sx={imageUploaderStyles.errorText}
          >
            {uploadError || error.message || "Image is required"}
          </Typography>
          <Typography variant="body2" sx={imageUploaderStyles.uploadText}>
            Supports JPG, JPEG, PNG
          </Typography>
          <Typography
            onClick={() => {
              fileInputRef.current.click();
            }}
            variant="subtext2"
            sx={{ color: "#EF4823", cursor: "pointer", mt: 1 }}
          >
            Upload image
          </Typography>
        </EmptyImageBox>
      );
    }

    return (
      <EmptyImageBox
        sx={imageUploaderStyles.defaultBox}
        onClick={() => {
          fileInputRef.current.click();
        }}
      >
        <img src={fileUpload} height="16px" width="16px" alt="" />
        <Typography my={1} variant="subtext2" sx={{ position: "relative" }}>
          Choose Image to Upload
          <span style={imageUploaderStyles.requiredmark}>*</span>
        </Typography>
        <Typography variant="body2" sx={imageUploaderStyles.uploadText}>
          Supports JPG, JPEG, PNG
        </Typography>
        <Typography my={1} variant="body4" sx={{ color: "#D32F2F" }}>
          Max allowed size is 150kb
        </Typography>
      </EmptyImageBox>
    );
  };

  return (
    <Box>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={2}
              style={{
                textAlign: "center",
                verticalAlign: "middle",
                cursor: "pointer",
                display: image ? "none" : "",
              }}
            >
              <input
                ref={fileInputRef}
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {renderUploadBox()}
            </Grid>
            {image && (
              <Grid item xs={2}>
                <Grid container direction="column">
                  <Grid item sx={imageUploaderStyles.deleteButton}>
                    <img
                      src={
                        image.downloadURL
                          ? image.downloadURL
                          : URL.createObjectURL(image.file)
                      }
                      alt="entityImage"
                      style={imageUploaderStyles.previewImage}
                    />
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleDelete}
                    >
                      <DeleteForeverIcon />
                      DELETE
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={3}
              style={{ display: uploadProgress > 0 ? "" : "none" }}
            >
              <Box>
                <Typography color="primary">{uploadProgress}%</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
