import { Box, styled } from "@mui/material";

export const ImageUploaderContainerGrid = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "0px 30px",
  borderRadius: "8px",
});
export const ImageBox = styled(Box)({
  width: "250px",
  height: "250px",
  position: "relative",
  "&:hover": {
    ".delete-icon": {
      display: "block",
    },
    ".replace-icon": {
      display: "flex",
    },
  },
});
export const DeleteIconBox = styled(Box)({
  position: "absolute",
  right: "10px",
  top: "10px",
  background: "#F1F1F1",
  borderRadius: "50%",
  height: 30,
  width: 30,
  cursor: "pointer",
  display: "none",
  opacity: 0.5,
});

export const ReplaceIconBox = styled(Box)({
  position: "absolute",
  left: "10px",
  bottom: "10px",
  background: "#F1F1F1",
  borderRadius: "45px",
  cursor: "pointer",
  display: "none",
  opacity: 0.5,
  padding: "5px 8px",
});
export const EmptyImageBox = styled(Box)({
  borderRadius: "8px",
  width: "250px",
  height: "250px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
});

//ControlledMediaSelectStyles
export const styles = {
  uploadBox: (error, uploadError, isUploading) => ({
    border: error || uploadError ? "2px dashed #D32F2F" : "2px dotted #A7A7A7",
    width: "200px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: isUploading ? "default" : "pointer",
    position: "relative",
  }),
  uploadingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 1,
  },
  selectedMedia: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "8px",
  },
  videoContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000",
    borderRadius: "8px",
    overflow: "hidden",
  },
  video: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  buttonContainer: {
    mt: 2,
    display: "flex",
    justifyContent: "space-between",
    width: "100px",
  },
  infoText: {
    fontSize: "14px",
    color: "#7C7B79",
  },
  errorMessage: {
    color: "#D32F2F",
    fontSize: "12px",
    mt: 1,
  },
};

// ImageUploaderStyles.js
export const imageUploaderStyles = {
  errorBox: {
    border: "2px dashed #D32F2F",
    width: "200px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
  },
  defaultBox: {
    border: "2px dotted #A7A7A7",
    width: "200px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
  },
  errorText: {
    fontSize: "14px",
    color: "#D32F2F",
  },
  uploadText: {
    fontSize: "14px",
    color: "#7C7B79",
  },
  deleteButton: {
    borderRadius: 5,
    border: "1px solid black",
    textAlign: "center",
  },
  previewImage: {
    width: 150,
    height: 150,
    objectFit: "contain",
  },
  requiredmark: {
    color: "#D32F2F",
    fontSize: "15px",
    position: "absolute",
    top: "0",
    zIndex: 1,
    lineHeight: "1",
  },
};
