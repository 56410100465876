import React, { useRef, useState } from "react";
import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  FormHelperText,
  Box,
  IconButton,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  CloudUpload as UploadIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useController } from "react-hook-form";
import { storage } from "../../../config/firebase-config";
import { v4 as uuidv4 } from "uuid";


// Rewriting MediaBoxStyles as a JavaScript object to make compatible with the sx prop
const MediaBoxStyles = {
  ".media-box": {
    position: "relative",
    padding: "16px",
    borderColor: "divider",
    borderRadius: "4px",
  },
  ".media-box.has-media": {
    padding: "44px 16px",
  },
};

const ControlledMultiMediaSelect = (props) => {
  const {
    control,
    name,
    options = [],
    label,
    size = "small",
    error,
    helperText,
    storageLocation,
  } = props;

  const [uploadingStates, setUploadingStates] = useState({});
  const [errorStates, setErrorStates] = useState({});
  const fileInputRefs = useRef({});

  const {
    field: { value: fieldValue, onChange },
    fieldState: { error: fieldError },
  } = useController({
    name,
    control,
    defaultValue: [],
  });

  // Ensure value is always an array
  const value = Array.isArray(fieldValue) ? fieldValue : [];

  const clearFileInput = (option) => {
    if (fileInputRefs.current[option]) {
      fileInputRefs.current[option].value = "";
    }
  };

  const handleMediaUpload = async (event, selectedOption) => {
    const file = event.target.files[0];
    if (!file || !selectedOption) return;

    if (file.size > 10 * 1024 * 1024) {
      const existingIndex = value.findIndex(
        (item) => item.option === selectedOption
      );
      if (existingIndex !== -1) {
        const updatedValue = [...value];
        updatedValue[existingIndex] = {
          option: selectedOption,
        };
        onChange(updatedValue);
      }

      // Set error state for this specific option
      setErrorStates((prev) => ({
        ...prev,
        [selectedOption]: "File size exceeds 10MB limit",
      }));
      clearFileInput(selectedOption);
      return;
    }

    // Clear any existing error for this option
    setErrorStates((prev) => ({
      ...prev,
      [selectedOption]: null,
    }));

    setUploadingStates((prev) => ({
      ...prev,
      [selectedOption]: true,
    }));

    try {
      const uuid = uuidv4();
      const storageRef = storage
        .ref()
        .child(
          `${storageLocation}/otherprepStyles/${selectedOption}/${uuid}-${file.name}`
        );
      const snapshot = await storageRef.put(file);
      const downloadURL = await snapshot.ref.getDownloadURL();

      const fileType = file.type.startsWith("video/") ? "video" : "image";

      const newMediaItem = {
        mediaUrl: downloadURL,
        option: selectedOption,
        fileType: fileType,
      };

      const existingIndex = value.findIndex(
        (item) => item.option === selectedOption
      );
      if (existingIndex !== -1) {
        const updatedValue = [...value];
        updatedValue[existingIndex] = newMediaItem;
        onChange(updatedValue);
      } else {
        onChange([...value, newMediaItem]);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrorStates((prev) => ({
        ...prev,
        [selectedOption]: "Error uploading file",
      }));
    } finally {
      setUploadingStates((prev) => ({
        ...prev,
        [selectedOption]: false,
      }));
      clearFileInput(selectedOption);
    }
  };

  const handleRemoveItem = async (itemToRemove) => {
    try {
      if (itemToRemove.mediaUrl) {
        const urlParts = itemToRemove.mediaUrl.split("/");
        const fileNameWithParams = urlParts[urlParts.length - 1];
        const fileName = fileNameWithParams.split("?")[0];

        const fullPath = `${storageLocation}/original/${decodeURIComponent(
          fileName
        )}`;

        try {
          const storageRef = storage.ref(fullPath);
          await storageRef.delete();
        } catch (storageError) {
          console.error("Error deleting from storage:", storageError);
        }
      }
      setErrorStates((prev) => ({
        ...prev,
        [itemToRemove.option]: null,
      }));

      clearFileInput(itemToRemove.option);
      const newValue = value.filter(
        (item) => item.option !== itemToRemove.option
      );
      onChange(newValue);
    } catch (error) {
      setErrorStates((prev) => ({
        ...prev,
        [itemToRemove.option]: "Error removing item",
      }));
    }
  };

  const handleOptionSelect = (selectedOption) => {
    if (!selectedOption) return;
    if (!value.find((item) => item.option === selectedOption)) {
      const newMediaItem = {
        option: selectedOption,
      };
      onChange([...value, newMediaItem]);
    }
  };

  const renderMediaPreview = (media) => {
    if (!media || !media.mediaUrl) {
      return null;
    }

    if (media.fileType === "video") {
      return (
        <video
          src={media.mediaUrl}
          controls
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      );
    }
    return (
      <img
        src={media.mediaUrl}
        alt={media.option}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    );
  };

  const isOptionSelected = (option) => {
    return value.some((item) => item.option === option);
  };

  const allOptions = options.filter((option) => !isOptionSelected(option));

  return (
    <Box sx={{ width: "100%", "& > *": { mb: 2 }, ...MediaBoxStyles }}>
      <FormControl fullWidth error={!!error || !!fieldError}>
        {label && <FormLabel>{label}</FormLabel>}

        <Select
          value=""
          onChange={(e) => handleOptionSelect(e.target.value)}
          displayEmpty
          size={size}
          sx={{ mb: 2 }}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              sx={{
                textTransform: "capitalize",
                ...(isOptionSelected(option) && {
                  backgroundColor: "rgba(239, 67, 41, 0.08)",
                  "&:hover": {
                    backgroundColor: "rgba(239, 67, 41, 0.08);",
                  },
                }),
              }}
            >
              {option}
            </MenuItem>
          ))}
        </Select>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gap: 2,
          }}
        >
          {value.map((media) => (
            <Box key={media.option}>
              <Box
                className={`media-box ${media.mediaUrl ? "has-media" : ""}`}
                sx={{
                  position: "relative",
                  border: "1px solid",
                  borderColor: errorStates[media.option]
                    ? "error.main"
                    : "divider",
                  borderRadius: 1,
                }}
              >
                {media && renderMediaPreview(media)}
                <Typography
                  variant="body2"
                  sx={{
                    textTransform: "capitalize",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {media.option}
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    display: "flex",
                    gap: 1,
                  }}
                >
                  {uploadingStates[media.option] ? (
                    <Box sx={{ display: "flex", alignItems: "center", px: 2 }}>
                      <CircularProgress size={20} />
                    </Box>
                  ) : (
                    <Button
                      component="label"
                      variant="outlined"
                      size="small"
                      startIcon={<UploadIcon />}
                    >
                      {media.mediaUrl ? "Replace" : "Upload"}
                      <input
                        type="file"
                        accept="image/*,video/*"
                        hidden
                        ref={(el) => (fileInputRefs.current[media.option] = el)}
                        onChange={(e) => handleMediaUpload(e, media.option)}
                      />
                    </Button>
                  )}
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveItem(media)}
                    color="error"
                    disabled={uploadingStates[media.option]}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
              {errorStates[media.option] && (
                <FormHelperText error sx={{ mt: 0.5 }}>
                  {errorStates[media.option]}
                </FormHelperText>
              )}
            </Box>
          ))}
        </Box>
        {(helperText || fieldError) && (
          <FormHelperText>{helperText || fieldError.message}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default ControlledMultiMediaSelect;
