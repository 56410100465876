import React from "react";
import { Toaster } from "react-hot-toast";
import "./toast.css";

function Toastify() {
  return (
    <Toaster
      position="bottom-left"
      reverseOrder={false}
      closeOnClick={true}
      gutter={8}
      containerClassName="container"
      containerStyle={{}}
      toastOptions={{
        className: "",
        duration: 5000,
        style: {
          background: "#363636",
          color: "#fff",
          height: "60px",
          minWidth: "fit-content",
          padding: "0 20px",
          whiteSpace: "nowrap",
        },
      }}
    />
  );
}

export default Toastify;
