import React, { useState, useRef, useEffect } from "react";
import { EmptyImageBox } from "../../rct-isolated-section/components/styles";
import fileUpload from "../../rct-isolated-section/assets/file-upload.png";
import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Box,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useController } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../../../config/firebase-config";
import { styles } from "../../rct-isolated-section/components/styles";

const ControlledMediaSelect = (props) => {
  const {
    control,
    name,
    options = [],
    label,
    handleMediaUpload,
    storageLocation,
    error,
  } = props;

  const { field } = useController({ name, control });
  const [selectedOption, setSelectedOption] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [showPreview, setShowPreview] = useState(true);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (field.value) {
      if (field.value.mediaUrl) {
        setSelectedOption(field.value);
        setShowPreview(true);
      } else {
        setSelectedOption({
          option: field.value.option,
          mediaUrl: null,
          fileType: null,
        });
        setShowPreview(false);
      }
    }
  }, [field.value]);

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileChange = async (e, option) => {
    const file = e.target.files[0];
    setUploadError("");

    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        setUploadError("File size exceeds 10MB limit");
        clearFileInput();
        setShowPreview(false);

        setSelectedOption((prev) => ({
          ...prev,
          mediaUrl: null,
          fileType: null,
        }));

        field.onChange({
          target: {
            name: field.name,
            value: {
              option: option,
              mediaUrl: null,
              fileType: null,
            },
          },
        });

        handleMediaUpload && handleMediaUpload({ [option]: null });

        return;
      }

      setIsUploading(true);
      try {
        const uuid = uuidv4();
        const storageRef = storage
          .ref()
          .child(
            `${storageLocation}/defaultprepStyles/${option}/${uuid}-${file.name}`
          );

        const snapshot = await storageRef.put(file);
        const downloadURL = await snapshot.ref.getDownloadURL();

        const fileType = file.type.startsWith("image/") ? "image" : "video";

        const newSelectedOption = {
          option,
          mediaUrl: downloadURL,
          fileType: fileType,
        };
        setSelectedOption(newSelectedOption);
        setShowPreview(true);
        field.onChange({
          target: {
            name: field.name,
            value: newSelectedOption,
          },
        });

        handleMediaUpload && handleMediaUpload({ [option]: downloadURL });
      } catch (error) {
        setUploadError("Error uploading file. Please try again.");
        setShowPreview(false);

        setSelectedOption((prev) => ({
          ...prev,
          mediaUrl: null,
          fileType: null,
        }));

        field.onChange({
          target: {
            name: field.name,
            value: {
              option: option,
              mediaUrl: null,
              fileType: null,
            },
          },
        });

        handleMediaUpload && handleMediaUpload({ [option]: null });
      } finally {
        setIsUploading(false);
        clearFileInput();
      }
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setUploadError("");
    setShowPreview(true);
    const newSelectedOption = {
      option: value,
      mediaUrl: null,
      fileType: null,
    };
    setSelectedOption(newSelectedOption);

    field.onChange({
      target: {
        name: field.name,
        value: newSelectedOption,
      },
    });
    clearFileInput();
  };

  const handleDelete = async () => {
    if (selectedOption && selectedOption.mediaUrl) {
      setSelectedOption({
        option: selectedOption.option,
        mediaUrl: null,
        fileType: null,
      });

      setShowPreview(false);
      field.onChange({
        target: {
          name: field.name,
          value: {
            option: selectedOption.option,
            mediaUrl: null,
            fileType: null,
          },
        },
      });
      clearFileInput();
      setUploadError("");
    }
  };

  const handleEditClick = () => {
    if (fileInputRef.current) {
      clearFileInput();
      fileInputRef.current.click();
    }
    setUploadError("");
  };

  const renderUploadBox = () => {
    if (!selectedOption?.mediaUrl || !showPreview) {
      return (
        <>
          <img src={fileUpload} height="16px" width="16px" alt="" />
          <Typography my={1} variant="subtext2" textAlign="center">
            Choose Image or Video to Upload
          </Typography>
          <Box textAlign="center">
            <Typography variant="body2" sx={styles.infoText}>
              Supports JPG, JPEG, PNG, MP4<span> (optional)</span>
            </Typography>
            {(error || uploadError) && (
              <Typography variant="body2" sx={styles.errorMessage}>
                {uploadError || error?.message || "Media is required"}
              </Typography>
            )}
            <Typography variant="body2" sx={styles.infoText}>
              Max allowed size is 10MB
            </Typography>
          </Box>
        </>
      );
    }

    if (selectedOption.fileType === "image") {
      return (
        <img
          src={selectedOption.mediaUrl}
          alt="Selected Media"
          style={styles.selectedMedia}
        />
      );
    }

    return (
      <Box sx={styles.videoContainer}>
        <video src={selectedOption.mediaUrl} controls style={styles.video} />
      </Box>
    );
  };

  return (
    <FormControl fullWidth>
      <FormLabel>{label}</FormLabel>
      <Select
        size="small"
        label={label}
        id={field.name}
        onChange={handleChange}
        onBlur={field.onBlur}
        name={field.name}
        inputRef={field.ref}
        value={selectedOption?.option || ""}
        fullWidth
        renderValue={(selected) => (
          <span style={{ textTransform: "capitalize" }}>{selected}</span>
        )}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            style={{ textTransform: "capitalize" }}
          >
            {option}
          </MenuItem>
        ))}
      </Select>

      {selectedOption && (
        <Box sx={{ mt: 2 }}>
          {(selectedOption.mediaUrl || selectedOption.option) && (
            <EmptyImageBox
              sx={styles.uploadBox(error, uploadError)}
              onClick={() => {
                if (!isUploading && fileInputRef.current) {
                  clearFileInput();
                  fileInputRef.current.click();
                }
              }}
            >
              {isUploading ? (
                <Box sx={styles.overlay}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                renderUploadBox()
              )}
              <input
                type="file"
                accept="image/*, video/*"
                ref={fileInputRef}
                onChange={(e) => handleFileChange(e, selectedOption.option)}
                style={{ display: "none" }}
              />
            </EmptyImageBox>
          )}

          {selectedOption.mediaUrl && showPreview && (
            <Box sx={styles.buttonContainer}>
              <IconButton onClick={handleEditClick} disabled={isUploading}>
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={handleDelete}
                disabled={!selectedOption.mediaUrl || isUploading}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      )}
    </FormControl>
  );
};

ControlledMediaSelect.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  handleMediaUpload: PropTypes.func,
  storageLocation: PropTypes.string.isRequired,
};

ControlledMediaSelect.defaultProps = {
  label: "",
  handleMediaUpload: () => {},
};

export default ControlledMediaSelect;
