import React, { createContext, useContext, useMemo, useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLocalStorage } from "./use-local-storage";
import { FirebaseApi, auth } from "../../config/firebase-config";
import firebase from "firebase";
import axios from "axios";
import { accessRoles, userAccessCollection } from "../../utils/constants";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const DOMAIN = "ftlabs.io";
const TOKEN_REFRESH_INTERVAL = 60 * 60 * 1000; // 1 hour

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [storedValue, setValue] = useLocalStorage("user", null);
  const [user, setUser] = useState(storedValue);
  const [isInitialized, setIsInitialized] = useState(false);
  const [dialogState, setDialogState] = useState({
    access: false,
    domain: false,
    logout: false
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [authToken, setAuthToken] = useState(null);

  const refreshTokenHandler = useCallback(async () => {
    try {
      const currentUser = auth().currentUser;
      if (!currentUser) return null;
      const newToken = await currentUser.getIdToken(true);
      setAuthToken(newToken);
      return newToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  }, []);

  const getUserAccess = useCallback(async (entityName) => {
    try {
      const snapshot = await FirebaseApi[entityName].get();
      return snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
    } catch (error) {
      console.error("Error fetching user access:", error);
      return [];
    }
  }, []);

  const findUserAccessRole = useCallback((userAccess, email) => {
    for (const role of accessRoles) {
      const roleInfo = userAccess?.find(item => item.id === role);
      const userInRole = roleInfo?.users?.find(val => val.email === email);
      if (userInRole) {
        return { ...userInRole, access: role };
      }
    }
    return null;
  }, []);

  const handleUserAuthentication = useCallback(async (firebaseUser) => {
    try {
      if (!firebaseUser) {
        setValue(null);
        setUser(null);
        if (location.pathname !== '/login') {
          navigate('/login', { replace: true });
        }
        return false;
      }

      const emailDomain = firebaseUser.email.split("@")[1];
      
      if (emailDomain !== DOMAIN) {
        setDialogState(prev => ({ ...prev, domain: true }));
        return false;
      }

      const userAccess = await getUserAccess(userAccessCollection);
      if (!userAccess?.length) return false;

      const token = await firebaseUser.getIdToken();
      setAuthToken(token);

      const userAccessRole = findUserAccessRole(userAccess, firebaseUser.email);
      if (!userAccessRole) {
        setDialogState(prev => ({ ...prev, access: true }));
        if (location.pathname !== '/login') {
          navigate("/login", { replace: true });
        }
        return false;
      }

      const userObject = {
        displayName: firebaseUser.displayName,
        userId: firebaseUser.uid,
        access: userAccessRole.access,
        email: firebaseUser.email,
        photoURL: firebaseUser.photoURL,
      };

      setUser(userObject);
      setValue(userObject);

      // Only navigate to home if we're on the login page
      if (location.pathname === '/login') {
        navigate("/", { replace: true });
      }
      return true;
    } catch (error) {
      console.error("Authentication error:", error);
      return false;
    }
  }, [getUserAccess, navigate, setValue, findUserAccessRole, location.pathname]);

  const signInWithGoogle = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = await auth().signInWithPopup(provider);
      await handleUserAuthentication(result.user);
    } catch (error) {
      console.error("Sign in error:", error);
      alert(error.message);
    }
  };

  const confirmLogout = async () => {
    try {
      await auth().signOut();
      setValue(null);
      setUser(null);
      setDialogState(prev => ({ ...prev, logout: false }));
      navigate("/login", { replace: true });
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged(async (firebaseUser) => {
      await handleUserAuthentication(firebaseUser);
      setIsInitialized(true);
    });

    return () => unsubscribe();
  }, [handleUserAuthentication]);

  useEffect(() => {
    if (user) {
      const refreshInterval = setInterval(refreshTokenHandler, TOKEN_REFRESH_INTERVAL);
      return () => clearInterval(refreshInterval);
    }
  }, [user, refreshTokenHandler]);

  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_DASHBOARD_API,
    });

    instance.interceptors.request.use(async (config) => {
      const token = authToken || (await refreshTokenHandler());
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    return instance;
  }, [authToken, refreshTokenHandler]);

  const contextValue = useMemo(
    () => ({
      user,
      signInWithGoogle,
      setOpenLogoutPopup: (state) => setDialogState(prev => ({ ...prev, logout: state })),
      authToken,
      refreshTokenHandler,
      api,
      isInitialized,
    }),
    [user, authToken, refreshTokenHandler, api, isInitialized]
  );

  // Don't render anything until Firebase Auth is initialized
  if (!isInitialized) {
    return null; // Or a loading spinner
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
      <Dialog 
        open={dialogState.access} 
        onClose={() => setDialogState(prev => ({ ...prev, access: false }))}
      >
        <DialogTitle>Access Denied</DialogTitle>
        <DialogContent>
          <p>You don't have access to open this Web app. Kindly request permission from an admin.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogState(prev => ({ ...prev, access: false }))}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={dialogState.domain} 
        onClose={() => setDialogState(prev => ({ ...prev, domain: false }))}
      >
        <DialogTitle>Unauthorized Domain</DialogTitle>
        <DialogContent>
          <p>You are not authorized to log in. You are not a Futuristic Lab user. Please log in using a Futuristic Lab email.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogState(prev => ({ ...prev, domain: false }))}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={dialogState.logout} 
        onClose={() => setDialogState(prev => ({ ...prev, logout: false }))}
      >
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to log out?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmLogout}>Yes</Button>
          <Button onClick={() => setDialogState(prev => ({ ...prev, logout: false }))}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};