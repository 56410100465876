/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
// Presets have a custom logic for the form submission so we will have to manually create the new/edit form
import React, { useEffect, useState } from "react";
import presets from "./presets-model";
import { v4 as uuidv4 } from "uuid";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  Select,
  Slider,
  Snackbar,
  Switch,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useController, useForm } from "react-hook-form";
import useEntity from "../entities/use-entity";
import ReactJson from "react-json-view";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { set } from "date-fns";

const DEBUG = false;

function ControlledCheckbox(props) {
  const { control, name, label } = props;
  const { field } = useController({
    name,
    control,
    //   rules: { required: true },
  });
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={field.name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          name={field.name}
          inputRef={field.ref}
          checked={field.value}
          error={props.error}
          helpertext={props.helpertext}
          required={field.required}
        />
      }
      label={label}
    />
  );
}

export default function PresetsCRUDForm(props) {
  const { schema, mode, values, updateValue, toggleModal } = props;
  const [formDefaultValues, setFormDefaultValues] = useState(values);

  const uniqueId = uuidv4();
  const { loading, loadingMessage, getEntities, addEntity, updateEntity } =
    useEntity();

  const [progressMessage, setProgressMessage] = useState("");
  const entityName = schema.collectionName;
  const formMode = mode;

  const [defaultValidationSchemaObject, setDefaultValidationSchemaObject] =
    useState({
      primaryName: yup
        .string()
        .required("Required")
        .matches(
          /^[a-zA-Z\s]+$/,
          "No special characters and numbers are allowed"
        )
        .max(40, "Maximum 40 characters allowed"),
      secondaryName: yup
        .string()
        .required("Required")
        .matches(
          /^[a-zA-Z\s]+$/,
          "No special characters and numbers are allowed"
        )
        .max(40, "Maximum 40 characters allowed"),
      isKlynkPreset: yup.boolean().required("isKlynkPreset? is required"),
      actionName: yup.string().required("Required"),
      controlModeName: yup.string().required("Required"),
    });

  const [validationSchema, setValidationSchema] = useState(
    yup.object().shape(defaultValidationSchemaObject)
  );

  const {
    control,
    register,
    setValue: setFormValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    validateOnChange: true,
    defaultValues:
      formDefaultValues && formMode === "edit"
        ? formDefaultValues
        : { ...schema.defaultObject, id: uniqueId },
    resolver: yupResolver(validationSchema),
  });

  const watchAllFields = watch();

  const [actions, setActions] = useState([]);
  const [controlModes, setControlModes] = useState([]);
  const [selectedControlMode, setSelectedControlMode] = useState(null);

  useEffect(() => {
    // get actions and control modes
    getEntities("actions", setActions);
    getEntities("controlModes", setControlModes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // on 'actionName' field change we will update the 'actionId' field we will use effect to do this
  useEffect(() => {
    const action = actions.find(
      (action) => action.name === watchAllFields.actionName
    );
    if (action) {
      setFormValue("actionId", action.id);
    }
  }, [actions, setFormValue, watchAllFields.actionName]);

  // on 'controlMode' field change we will update the 'controlModeId' field we will use effect to do this
  useEffect(() => {
    const controlMode = controlModes.find(
      (controlMode) => controlMode.name === watchAllFields.controlModeName
    );
    if (controlMode) {
      setSelectedControlMode(controlMode);
      let extraValidations = defaultValidationSchemaObject;
      if (controlMode.isCentralProbeTempratureRequired) {
        extraValidations["centralTemperatureInC"] = yup
          .number()
          .required("Central Temperature is required");
      }
      if (controlMode.isTimeRequired) {
        extraValidations["timeInMinuites"] = yup
          .number()
          .required("Time is required");
      }
      if (controlMode.isPowerRequired) {
        extraValidations["powerInLevel"] = yup
          .number()
          .required("Power is required");
      }
      if (controlMode.isExternalProbeTempratureRequired) {
        extraValidations["externalTemperatureInC"] = yup
          .number()
          .required("External Temperature is required");
      }
      if (controlMode.isExternalProbeEnabled) {
        extraValidations["externalProbeEnabled"] = yup
          .boolean()
          .required("External Probe Enabled is required");
      }
      if (controlMode.isControlViaExternalProbe) {
        extraValidations["externalProbeControl"] = yup
          .boolean()
          .required("External Probe Control is required");
      }
      setValidationSchema(
        yup.object().shape({
          ...extraValidations,
        })
      );
      setFormValue("controlModeId", controlMode.id);
      setFormValue("controlModeFirmwareId", controlMode.firmwareId);
    }
  }, [
    controlModes,
    defaultValidationSchemaObject,
    setFormValue,
    watchAllFields.controlModeName,
  ]);

  const onSubmit = async (data) => {
    console.log("Form Data", data);
    setProgressMessage("Please wait...");
    if (formMode && formMode === "edit") {
      await updateEntity(entityName, data, setFormDefaultValues);
      await updateValue(data);
    } else {
      await addEntity(entityName, data, setFormDefaultValues);
      await updateValue(data);
    }
    setProgressMessage("");
    toggleModal();
  };

  return (
    <Box
      padding={2}
      style={{ background: "white", height: "100vh", overflow: "scroll" }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={loading}
        message={loadingMessage}
        autoHideDuration={6000}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={progressMessage.length > 0 ? true : false}
        message={progressMessage}
        autoHideDuration={6000}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              top: 0,
              position: "fixed",
              padding: 15,
              background: "#e8e8e8",
              width: "100%",
              zIndex: 100,
            }}
          >
            <Typography variant="h2">
              {formMode === "edit" ? "Update" : "Add"} {schema.name}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 60 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={
                        errors.primaryName ||
                        watchAllFields.primaryName?.length > 40
                      }
                    >
                      <TextField
                        size="small"
                        fullWidth
                        label={
                          <>
                            {"Primary Name"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </>
                        }
                        variant="outlined"
                        {...register("primaryName", {
                          required: true,
                          maxLength: 40,
                          onChange: (e) => {
                            setFormValue(
                              "primaryName",
                              e.target.value.slice(0, 40)
                            );
                          },
                        })}
                        error={
                          !!errors.primaryName ||
                          watchAllFields.primaryName?.length > 40
                        }
                        helperText={
                          errors.primaryName?.message ||
                          (watchAllFields.primaryName?.length > 40
                            ? "Error: Maximum 40 characters allowed"
                            : `${
                                watchAllFields.primaryName?.length || 0
                              }/40 characters`)
                        }
                        InputProps={{
                          inputProps: {
                            maxLength: 40,
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={
                        errors.secondaryName ||
                        watchAllFields.secondaryName?.length > 40
                      }
                    >
                      <TextField
                        size="small"
                        fullWidth
                        label={
                          <>
                            {"Secondary Name"}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </>
                        }
                        variant="outlined"
                        {...register("secondaryName", {
                          required: true,
                          maxLength: 40,
                          onChange: (e) => {
                            setFormValue(
                              "secondaryName",
                              e.target.value.slice(0, 40)
                            );
                          },
                        })}
                        error={
                          !!errors.secondaryName ||
                          watchAllFields.secondaryName?.length > 40
                        }
                        helperText={
                          errors.secondaryName?.message ||
                          (watchAllFields.secondaryName?.length > 40
                            ? "Error: Maximum 40 characters allowed"
                            : `${
                                watchAllFields.secondaryName?.length || 0
                              }/40 characters`)
                        }
                        InputProps={{
                          inputProps: {
                            maxLength: 40,
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ControlledCheckbox
                      label={"isKlynkPreset?"}
                      variant="outlined"
                      name={"isKlynkPreset"}
                      control={control}
                      helpertext={errors.isKlynkPreset?.message}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ControlledCheckbox
                      label={"showInRecipeBuilder?"}
                      variant="outlined"
                      name={"showInRecipeBuilder?"}
                      control={control}
                      error={errors["showInRecipeBuilder?"]}
                      helpertext={errors["showInRecipeBuilder?"]?.message}
                    />
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth error={!!errors.actionName}>
                      <FormLabel>
                        {"Action"}
                        <span style={{ color: "red" }}>*</span>
                      </FormLabel>
                      <Select
                        size={props.size ? props.size : "small"}
                        label={"Action"}
                        fullWidth
                        {...register("actionName")}
                        onChange={(e) => {
                          console.log("Action", e.target.value);
                          setFormValue("actionName", e.target.value);
                        }}
                        value={watchAllFields.actionName}
                        error={!!errors.actionName}
                      >
                        {Array.from(
                          new Set(actions.map((option) => option["name"]))
                        ).map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.actionName && (
                        <FormHelperText>
                          {errors.actionName.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <FormLabel>{"Action ID"}</FormLabel>
                      <TextField
                        size="small"
                        fullWidth
                        disabled
                        variant="outlined"
                        {...register("actionId")}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth error={!!errors.controlModeName}>
                      <FormLabel>
                        {"Control Mode"}
                        <span style={{ color: "red" }}>*</span>
                      </FormLabel>
                      <Select
                        size={props.size ? props.size : "small"}
                        label={"Control Mode"}
                        fullWidth
                        {...register("controlModeName")}
                        onChange={(e) => {
                          console.log("Control Mode", e.target.value);
                          setFormValue("controlModeName", e.target.value);
                        }}
                        multiple={false}
                        error={!!errors.controlModeName}
                        value={watchAllFields.controlModeName}
                      >
                        {controlModes
                          .map((option) => option["name"])
                          .map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                      </Select>
                      {errors.controlModeName && (
                        <FormHelperText>
                          {errors.controlModeName.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <FormLabel>{"ControlMode ID"}</FormLabel>
                      <TextField
                        size="small"
                        fullWidth
                        disabled
                        variant="outlined"
                        {...register("controlModeId")}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      label={"centralTemperatureInC"}
                      variant="outlined"
                      helpertext={errors.centralTemperatureInC?.message}
                      {...register("centralTemperatureInC")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      label={"timeInMinuites"}
                      variant="outlined"
                      helpertext={errors.timeInMinuites?.message}
                      {...register("timeInMinuites")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      label={"powerInLevel"}
                      variant="outlined"
                      helpertext={errors.powerInLevel?.message}
                      {...register("powerInLevel")}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      fullWidth
                      label={
                        selectedControlMode?.isExternalProbeTempratureRequired
                          ? "externalTemperatureInC (Required)"
                          : "externalTemperatureInC (Optional)"
                      }
                      variant="outlined"
                      helpertext={errors.externalTemperatureInC?.message}
                      {...register("externalTemperatureInC")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ControlledCheckbox
                      label={"externalProbeEnabled?"}
                      variant="outlined"
                      name={"externalProbeEnabled"}
                      control={control}
                      helpertext={errors.externalProbeEnabled?.message}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ControlledCheckbox
                      label={"externalProbeControl?"}
                      variant="outlined"
                      name={"externalProbeControl"}
                      control={control}
                      helpertext={errors.externalProbeControl?.message}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              bottom: 0,
              position: "fixed",
              padding: 15,
              background: "#e8e8e8",
              width: "100%",
              zIndex: 100,
            }}
          >
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      {DEBUG && (
        <Box style={{ width: "80%" }}>
          <ReactJson src={watchAllFields} theme={"monokai"} />
        </Box>
      )}
    </Box>
  );
}
