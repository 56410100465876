/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */

// Importing necessary libraries and components
import React, { useContext } from "react";
import { Grid, Box, Typography, InputAdornment, Divider } from "@mui/material";
import ModifiedTextField from "./modified-field/modified-text-field";
import { useFormContext } from "react-hook-form";
import ModifiedAutoCompleteWithTags from "./modified-field/modified-autocomplete-tags";
import SingleImageUploader from "./components/image-uploader";
import useRecipeMetaData from "./hooks/use-recipe-meta-data";
import { RecipeEntityContext } from "./hooks/entity-context";
import {
  SERVINGS_NUMBERS,
  difficultyLevelArray,
  vegetarian,
} from "./constants";
import CompatibilityPreventPopup from "./components/compatibility-prevent-popup";
import BlockThreeCoursesPopup from "./components/block-three-courses-popup";
import DietSelectionPopup from "./components/diet-selection-popup";

const stylesheet = {
  servingsButton: {
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "8px",
    marginRight: "7px",
    boxShadow: " 0px 0px 4px 0px rgba(0, 0, 0, 0.05)",
    textTransform: "capitalize",
    "&:hover": {
      // border: "1px solid #EF4823 ",
      background: "#FFECE4",
    },
  },
};

// Metadata component
const Metadata = () => {
  const location = window.location.hostname;
  const {
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const { tools, tags, recipeFilters, courses, cuisines, allergies, diets } =
    useContext(RecipeEntityContext);
  const {
    semiSelected,
    rikuSelected,
    difficulty,
    servings,
    totalTime,
    selectServingsHandler,
    selectDifficultyHandler,
    selectCompatibilityHandler,
    isModalOpen,
    setIsModalOpen,
    handleConfirm,
    isCourseModelOpen,
    setIsCourseModelOpen,
    isDietSelection,
    setIsDietSelection,
    replaceDietsHandler,
  } = useRecipeMetaData({ watch, setValue, getValues, clearErrors });
  const metaData = getValues("metadata");
  const recipeId = getValues("metadata").recipeId;

  // Rendering Metadata component
  return (
    <Grid item xs={8} width="100%">
      <CompatibilityPreventPopup
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleConfirm={handleConfirm}
      />
      <BlockThreeCoursesPopup
        isCourseModelOpen={isCourseModelOpen}
        setIsCourseModelOpen={setIsCourseModelOpen}
      />
      <DietSelectionPopup
        isDietSelection={isDietSelection}
        setIsDietSelection={setIsDietSelection}
        replaceDietsHandler={replaceDietsHandler}
        isVegTag={metaData.diets?.includes(vegetarian)}
      />

      <Grid item rowGap={4} height="100%" display="flex" flexDirection="column">
        <Divider />
        <Grid
          xs={12}
          container
          item
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Grid xs={8} item height="100%">
            <Grid xs={6} item>
              <Typography mb={1} variant="h5">
                Recipe title <span style={{ color: "#EF4823" }}>*</span>
              </Typography>

              <ModifiedTextField
                variant="outlined"
                placeholder="Recipe title here"
                error={errors?.metadata?.title}
                style={{
                  borderRadius: "4px",
                }}
                fullWidth
                control={control}
                name="metadata.title"
                inputProps={{ maxLength: 60 }}
              />
              <Box display="flex" justifyContent="space-between">
                {errors?.metadata?.title && (
                  <Typography variant="caption2" color="#EF4823">
                    {errors?.metadata?.title?.message}
                  </Typography>
                )}
                <Box flexGrow={1} display="flex" justifyContent="flex-end">
                  <Typography
                    variant="caption1"
                    sx={{ textAlign: "end", mt: 1 }}
                  >{`${metaData?.title?.length} / 60`}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid mt={4} item>
              <Typography mb={1} variant="h5">
                Description
                <span style={{ color: "#EF4823" }}> *</span>
              </Typography>
              <ModifiedTextField
                error={errors?.metadata?.recipeDescription}
                variant="outlined"
                placeholder="Write a short description about your recipe here"
                fullWidth
                control={control}
                style={{
                  borderRadius: "4px",
                }}
                name="metadata.recipeDescription"
                multiline
                inputProps={{ maxLength: 500 }}
                rows={3}
              />
            </Grid>
            <Box display="flex" justifyContent="space-between">
              {errors?.metadata?.recipeDescription && (
                <Typography variant="caption2" color="#EF4823">
                  {errors?.metadata?.recipeDescription?.message}
                </Typography>
              )}
              <Box flexGrow={1} display="flex" justifyContent="flex-end">
                <Typography
                  variant="caption1"
                  sx={{ textAlign: "end", mt: 1 }}
                >{`${metaData?.recipeDescription?.length} / 500`}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid xs={3.2} mt={1} item>
            {" "}
            <SingleImageUploader
              imageUrl={metaData.thumbnailUrl}
              multiple={false}
              storageLocation={`recipes/${recipeId}`}
              name="metadata.thumbnailUrl"
              errorMessage={errors?.metadata?.thumbnailUrl?.message}
            />
          </Grid>
        </Grid>
        <Grid mt={4}>
          <Grid
            container
            xs={9}
            sx={{
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Grid item sx={{ display: "flex", flexDirection: "column" }}>
              <Grid container>
                <Grid item>
                  <Box>
                    <Typography mb={1} variant="h5">
                      Prep time <span style={{ color: "#EF4823" }}> * </span>
                    </Typography>

                    <ModifiedTextField
                      fullWidth
                      variant="outlined"
                      type="number"
                      size="small"
                      error={errors?.metadata?.prepTimeInMins}
                      style={{
                        // padding: "4px",
                        width: "100px",

                        borderRadius: "4px",
                      }}
                      control={control}
                      name="metadata.prepTimeInMins"
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            fontFamily: "Mukta Mahee",
                            fontSize: "16px",
                            fontWeight: 400,
                            color: "#393939",
                          }}
                        >
                          <Typography variant="body4">mins</Typography>
                        </InputAdornment>
                      }
                    />
                  </Box>

                  {errors?.metadata?.prepTimeInMins && (
                    <Typography variant="caption2" color="#EF4823">
                      {errors.metadata.prepTimeInMins.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item mx={2}>
                  <Box>
                    {" "}
                    <Typography mb={1} variant="h5">
                      Cook time <span style={{ color: "#EF4823" }}> * </span>
                    </Typography>
                    <ModifiedTextField
                      fullWidth
                      variant="outlined"
                      error={errors?.metadata?.cookTimeInMins}
                      placeholder="-"
                      type="number"
                      size="small"
                      style={{
                        // padding: "4px ",
                        width: "110px",
                        borderRadius: "4px",
                      }}
                      control={control}
                      name="metadata.cookTimeInMins"
                      endAdornment={
                        <InputAdornment position="end" variant="standard">
                          <Typography variant="body4">mins</Typography>
                        </InputAdornment>
                      }
                    />
                  </Box>

                  {errors?.metadata?.cookTimeInMins && (
                    <Typography variant="caption2" color="#EF4823">
                      {errors.metadata.cookTimeInMins.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography mb={1} variant="h5">
                    Rest time
                    {/* <span style={{ color: "#EF4823" }}> * </span> */}
                  </Typography>

                  <ModifiedTextField
                    fullWidth
                    variant="outlined"
                    type="number"
                    size="small"
                    style={{
                      // padding: "4px 4px",
                      width: "100px",
                    }}
                    control={control}
                    name="metadata.restTimeInMins"
                    endAdornment={
                      <InputAdornment position="end" variant="outlined">
                        <Typography variant="body4">hrs</Typography>
                      </InputAdornment>
                    }
                  />
                </Grid>
              </Grid>
              <Grid>
                <Typography variant="subtext1" mt={1}>
                  {totalTime}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography mb={1} variant="h5">
                Difficulty <span style={{ color: "#EF4823" }}>*</span>
              </Typography>
              <Box display="flex">
                {difficultyLevelArray.map((difficultyItem, difficultyIndex) => (
                  <Box
                    key={difficultyIndex}
                    onClick={() => selectDifficultyHandler(difficultyItem)}
                    variant="outlined"
                    sx={{
                      ...stylesheet.servingsButton,
                      border:
                        difficulty === difficultyItem
                          ? "2px solid #EF4823"
                          : "2px solid #f1f1f1",
                      background: difficulty === difficultyItem && "#FFECE4",
                    }}
                  >
                    <Typography
                      variant="subtext1"
                      sx={{
                        fontWeight: 500,
                        color: difficulty === difficultyItem && "#EF4823",
                      }}
                    >
                      {difficultyItem}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {errors?.metadata?.difficultyLevel && (
                <Typography variant="caption2" color="#EF4823">
                  {errors.metadata.difficultyLevel.message}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            xs={9}
            sx={{
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Grid item>
              <Typography mb={1} variant="h5">
                Servings <span style={{ color: "#EF4823" }}>*</span>
              </Typography>

              <Box sx={{ display: "flex" }}>
                {SERVINGS_NUMBERS.map((servingsItem, servingsIndex) => (
                  <Box
                    key={servingsIndex + 1}
                    onClick={() => selectServingsHandler(servingsItem)}
                    sx={{
                      ...stylesheet.servingsButton,
                      border:
                        servings === servingsItem
                          ? "2px solid #EF4823"
                          : "2px solid #f1f1f1",
                      background:
                        servings === servingsItem ? "#FFECE4" : "transparent",
                      padding: " 10px 20px",
                    }}
                  >
                    <Typography
                      variant="subtext1"
                      sx={{
                        fontWeight: 500,
                        color:
                          servings === servingsItem ? "#EF4823" : "inherit",
                      }}
                    >
                      {servingsItem}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {errors?.metadata?.servings && (
                <Typography variant="caption2" color="#EF4823">
                  {errors.metadata.servings.message}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Typography mb={1} variant="h5">
                Compatibility <span style={{ color: "#EF4823" }}>*</span>
              </Typography>
              <Box display="flex">
                <Box
                  // onClick={() => selectCompatibilityHandler(0)}
                  variant="outlined"
                  sx={{
                    ...stylesheet.servingsButton,
                    border: "2px solid #EF4823",

                    background: "#FFECE4",
                  }}
                >
                  <Typography
                    variant="subtext1"
                    sx={{
                      fontWeight: 500,
                      color: "#EF4823",
                    }}
                  >
                    Manual
                  </Typography>
                </Box>
                <Box
                  onClick={() => selectCompatibilityHandler(0)}
                  variant="outlined"
                  sx={{
                    ...stylesheet.servingsButton,
                    border: semiSelected
                      ? "2px solid #EF4823"
                      : "2px solid #f1f1f1",
                    background: semiSelected && "#FFECE4",
                  }}
                >
                  <Typography
                    variant="subtext1"
                    sx={{
                      fontWeight: 500,
                      color: semiSelected && "#EF4823",
                    }}
                  >
                    Semi
                  </Typography>
                </Box>
                {location !== "app.klynk.recipes" && (
                  <Box
                    onClick={() => selectCompatibilityHandler(1)}
                    variant="outlined"
                    sx={{
                      ...stylesheet.servingsButton,
                      border: rikuSelected
                        ? "2px solid #EF4823"
                        : "2px solid #f1f1f1",
                      background: rikuSelected && "#FFECE4",
                    }}
                  >
                    <Typography
                      variant="subtext1"
                      sx={{
                        fontWeight: 500,
                        color: rikuSelected && "#EF4823",
                      }}
                    >
                      Riku
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            mb={4}
            xs={12}
            spacing={4}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <Typography mb={1} variant="h5">
                Course
                <span style={{ color: "#EF4823" }}>*</span>
              </Typography>
              <ModifiedAutoCompleteWithTags
                setIsCourseModelOpen={setIsCourseModelOpen}
                setIsDietSelection={setIsDietSelection}
                control={control}
                name="metadata.courses"
                options={courses}
                multiple
                error={errors.metadata?.courses}
              />
              {errors?.metadata?.courses && (
                <Typography variant="caption2" color="#EF4823">
                  {errors.metadata.courses.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography mb={1} variant="h5">
                Tools
                <span style={{ color: "#EF4823" }}>*</span>
              </Typography>
              <ModifiedAutoCompleteWithTags
                setIsCourseModelOpen={setIsCourseModelOpen}
                setIsDietSelection={setIsDietSelection}
                control={control}
                name="metadata.tools"
                options={tools}
                multiple
                error={errors.metadata?.tools}
              />
              {errors?.metadata?.tools && (
                <Typography variant="caption2" color="#EF4823">
                  {errors.metadata.tools.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography mb={1} variant="h5">
                Diets
                <span style={{ color: "#EF4823" }}>*</span>
              </Typography>
              <ModifiedAutoCompleteWithTags
                control={control}
                setIsCourseModelOpen={setIsCourseModelOpen}
                setIsDietSelection={setIsDietSelection}
                name="metadata.diets"
                options={diets}
                multiple
                size="small"
                error={errors.metadata?.diets}
              />
              {errors?.metadata?.diets && (
                <Typography variant="caption2" color="#EF4823">
                  {errors.metadata.diets.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography mb={1} variant="h5">
                Cuisine
                <span style={{ color: "#EF4823" }}>*</span>
              </Typography>
              <ModifiedAutoCompleteWithTags
                control={control}
                setIsDietSelection={setIsDietSelection}
                setIsCourseModelOpen={setIsCourseModelOpen}
                name="metadata.cuisines"
                options={cuisines}
                multiple
                error={errors.metadata?.cuisines}
              />
              {errors?.metadata?.cuisines && (
                <Typography variant="caption2" color="#EF4823">
                  {errors.metadata.cuisines.message}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

// Exporting Metadata component
export default Metadata;
