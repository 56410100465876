import React, { useCallback, useMemo } from "react";
import { useController } from "react-hook-form";
import { Mention, MentionsInput } from "react-mentions";
import mentionsStyle from "./mention-input-styles";
import "./modified.css";

const ModifiedMentionsInput = ({
  instructionId,
  ingredients = [],
  setSelectedIngredients,
  setMentionTitleArray,
  setDisplayTitle,
  style,
  metaData,
  control,
  name,
}) => {
  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });

  const maxCharacterLimit = useMemo(() => 
    metaData?.productReleases?.length > 0 ? 60 : 160
  , [metaData?.productReleases?.length]);

  const getUsedIngredientsFromText = useCallback((text) => {
    if (!text) return [];
    const matches = text.match(/@\[(.*?)\]/g) || [];
    return matches.map((match) => {
      const [name] = match.slice(2, -1).split(",");
      return name.trim();
    });
  }, []);

  const ingredientForMentions = useMemo(() => 
    ingredients
      ?.filter(item => item?.instructionId === "" && item?.name)
      ?.map(ingredient => ({
        id: [ingredient.name, ingredient?.quantity, ingredient?.units],
        display: ingredient.name,
        quantity: ingredient?.quantity,
        unit: ingredient?.units,
      }))
  , [ingredients]);

  const handleMentionsInputChange = useCallback((
    event,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    const ingredientNames = ingredients.map(ing => ing.name);
    const selectionIngredients = mentions
      .filter(mention => ingredientNames.includes(mention.display))
      .map(mention => mention.id);

    setMentionTitleArray(selectionIngredients);
    setSelectedIngredients(selectionIngredients, instructionId);
    field.onChange(newValue);
    setDisplayTitle(newPlainTextValue);

    // Update ingredient instructions
    const currentIngredients = getUsedIngredientsFromText(newValue);
    ingredients.forEach(ing => {
      if (
        ing.instructionId === instructionId && 
        !currentIngredients.includes(ing.name)
      ) {
        ing.instructionId = "";
      }
    });
  }, [
    ingredients,
    setMentionTitleArray,
    setSelectedIngredients,
    instructionId,
    field,
    setDisplayTitle,
    getUsedIngredientsFromText
  ]);

  const renderSuggestion = useCallback((suggestion, search, highlightedDisplay) => (
    <div style={{ padding: 1 }}>
      {highlightedDisplay}
      <span style={{ marginLeft: "10px" }}>{suggestion.quantity}</span>
      <span style={{ marginLeft: "2px" }}>{suggestion.unit}</span>
    </div>
  ), []);

  return (
    <MentionsInput
      value={field.value}
      onBlur={field.onBlur}
      onChange={handleMentionsInputChange}
      inputRef={field.ref}
      name={field.name}
      style={{ ...style, ...mentionsStyle }}
      placeholder="Use {@} for ingredients"
      maxLength={maxCharacterLimit}
    >
      <Mention
        trigger="@"
        className="mentions__user"
        data={ingredientForMentions}
        appendSpaceOnAdd
        renderSuggestion={renderSuggestion}
      />
    </MentionsInput>
  );
};

export default React.memo(ModifiedMentionsInput);