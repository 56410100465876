import { FirebaseApi } from "../../config/firebase-config";
import { v4 as uuidv4 } from "uuid";

export const useLogsHook = () => {
  function formatTimestamp({ seconds }) {
    const date = new Date(seconds * 1000);
    return new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    })
      .format(date)
      .replace(",", "");
  }

  const getEntities = async (entityName) => {
    try {
      const data = await FirebaseApi[entityName]
        .orderBy("createdAt", "desc")
        .get();

      const parsedData = data.docs.map((item) => {
        const itemData = item.data();
        return {
          ...itemData,
          id: itemData.id || uuidv4(),
        };
      });

      return parsedData;
    } catch (error) {
      console.error("error", error);
      return [];
    }
  };
  return {
    formatTimestamp,
    getEntities,
  };
};
