/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
// ControlledAutoComplete is a component that allows the user to enter a list of options that they can choose from.
// It has an onChange prop that allows the user to change the value of the component, and a value prop that allows the user to set the value of the component.
// The component also has a getOptionLabel prop that allows the user to customize the label of the options that they can choose from.
import React, { useEffect, useState, useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import PropTypes from "prop-types";

const ControlledAutoComplete = ({
  control,
  name,
  options = [],
  label,
  multiple = false,
  collectionLookupKey = "name",
  size = "small",
  onRecipesChange,
}) => {
  const { field } = useController({
    name,
    control,
    //   rules: { required: true },
  });

  const [existingRecipes, setExistingRecipes] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (name === "recipeList" && field.value) {
      setExistingRecipes(field.value);
    }
  }, []);

  const filteredOptions = useMemo(() => {
    if (inputValue.trim().length > 0) {
      return options.filter((option) => {
        const optionLabel =
          typeof option === "string"
            ? option
            : option[collectionLookupKey] || "";
        return optionLabel.toLowerCase().includes(inputValue.toLowerCase());
      });
    }
    return options;
  }, [options, inputValue, collectionLookupKey]);

  return (
    <Autocomplete
      size={size}
      disablePortal
      id={field.name}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => setOpen(false)}
      onChange={(evt, newValue) => {
        field.onChange(newValue);
        if (name === "recipeList" && onRecipesChange) {
          onRecipesChange(newValue, existingRecipes);
        }
        setOpen(false);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        setOpen(true);
      }}
      inputValue={inputValue}
      onBlur={field.onBlur}
      name={field.name}
      freeSolo
      options={filteredOptions}
      value={field.value !== undefined ? field.value : multiple ? [] : null}
      multiple={multiple}
      getOptionLabel={(option) => {
        const label = typeof option === "string" ? option : option[collectionLookupKey] || "N/A";
        return label.charAt(0).toUpperCase() + label.slice(1);
      }}
      isOptionEqualToValue={(option, value) => {
        return option?.id === value?.id;
      }}
      filterOptions={(options) => options}
      renderOption={(props, option) => (
        <li {...props} key={option.id} style={{ textTransform: "capitalize" }}>
          {typeof option === "string" ? option : option[collectionLookupKey] || "N/A"}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Choose from the list"
          fullWidth
          label={label}
          inputRef={field.ref}
          sx={{
            ".MuiInputBase-input": {
              textTransform: "capitalize",
              fontFamily: "Mukta Mahee",
              fontSize: "16px",
            },
          }}
        />
      )}
    />
  );
};

ControlledAutoComplete.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  collectionLookupKey: PropTypes.string,
  size: PropTypes.string,
  onRecipesChange: PropTypes.func,
};

export default ControlledAutoComplete;